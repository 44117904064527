import React, { useState } from 'react'
import Code from '../doc/Code/'
import ContextMenu from './index'
import Button from '../Button'
import Icon from '../Icon'

class example extends React.Component {

  state = {
    showDropdown: false
  }

  render() {
    const { showDropdown } = this.state
    return (
      <div>
        <Code code={`<ContextMenu visible={true} arrow>
  <ContextMenu.Item>
    <span>Test</span>
  </ContextMenu.Item>
  <ContextMenu.Separator />
  <ContextMenu.Item
    onClick={() => {}}
    Icon={<Icon name={'person'} size={15} />}
  >
    Företaget AB
  </ContextMenu.Item>
</ContextMenu>`}
        />

        <div>
          <h4>Props</h4>
          <table width="100%" className="props-table">
            <tbody>

            </tbody>
          </table>
        </div>

        <div className="prev">
          <h3 className="label">ContextMenu</h3>
          <ContextMenu visible={true} arrow>
            <ContextMenu.Item>
              <span>Test</span>
            </ContextMenu.Item>
            <ContextMenu.Separator />
            <ContextMenu.Item
              onClick={() => { }}
              Icon={<Icon name={'person'} size={15} />}
            >
              Företaget AB
            </ContextMenu.Item>
            <ContextMenu.Item onClick={() => { }}>
              Företaget 2 AB
            </ContextMenu.Item>
            <ContextMenu.Separator />
            <ContextMenu.Item>
              <a href="#">
                Skapa företag +
              </a>
            </ContextMenu.Item>
          </ContextMenu>
        </div>


        <div className="prev" style={{ marginTop: 180 }}>
          <h3 className="label">ContextMenu</h3>
          <div style={{ position: 'relative' }}>
            <Button.Primary
              onClick={() => {
                this.setState({ showDropdown: !showDropdown })
              }}
            >
              Add new item +
            </Button.Primary>

            <ContextMenu
              visible={showDropdown}
              arrow
              onBlur={() => this.setState({ showDropdown: false })}
              style={{ top: 40 }}
            >
              <ContextMenu.Item
                onClick={() => { }}
                Icon={<Icon name={'create'} size={14} />}
              >
                <span>Simple</span>
              </ContextMenu.Item>
              <ContextMenu.Item
                onClick={() => { }}
                Icon={<Icon name={'cloud-upload'} size={14} />}
              >
                <span>From file</span>
              </ContextMenu.Item>
            </ContextMenu>

          </div>


        </div>

      </div>
    )
  }
}

export default example;
