import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loader from '../Loader';
import Icon from '../Icon';
import styles from './select.css';


class Select extends React.Component {

  constructor(props) {
    super(props)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      if (nextProps.options.length === 0) {
        return null
      }
      let index = 0;
      for (let i = 0; i < nextProps.options.length; i++) {
        if (nextProps.value === nextProps.options[i].value) {
          index = i;
          break
        }
      }
      return { textValue: nextProps.options[index].label, value: nextProps.value }
    }
    return null
  }

  state = {
    textValue: '',
    value: '',
    showOptions: false,
  };

  _onChange = (value) => {
    const { options } = this.props;
    let index = -1;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        index = i;
        break;
      }
    }
    if (index < 0) {
      return null;
    }
    this.setState({
      textValue: options[index].label,
      showOptions:false
    });

    this.props.onChange({
      value: options[index].value,
      text: options[index].label
    })
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.showOptions) {
        event.preventDefault()
        this.setState({showOptions:false});
      }
    }
  }

  renderOptionIcon(Icon) {
    if (typeof Icon === 'string') {
      return (
        <div className={styles.ficon}>
          <i className={Icon}></i>
        </div>
      )
    } else if (Icon) {
      return (
        <div className={styles.ficon}>
          {Icon}
        </div>
      )
    };
    return null;
  }

  renderOptions() {
    const { showOptions } = this.state;
    const { options, description } = this.props;

    if (!showOptions) {
      return null
    }

    return (
      <ul className={styles.options}>
        <li className={styles.description}>{description}</li>
        {options.map((option) => (
          <li className={classNames(styles.option, {[`${styles.disabled}`]: option.disabled})} onClick={() => {if (!option.disabled) this._onChange(option.value)}} key={option.value}>
            {this.renderOptionIcon(option.icon)}
            <span className={styles.optionsLabel}>{option.label}</span>
            {option.description && <span className={styles.optionsDescription}>{option.description}</span>}
          </li>
        ))}
      </ul>
    )
  };

  renderIcon() {
    const { Icon, loading } = this.props;
    if (loading) {
      return (
        <div className={styles.ficon}>
          <Loader small color={'gray'} />
        </div>
      )
    }
    if (Icon !== false && typeof Icon === 'string') {
      return (
        <div className={classNames(styles.ficon, styles.mainIcon)}>
          <i className={Icon}></i>
        </div>
      )
    } else if (Icon !== false) {
      return (
        <div className={classNames(styles.ficon, styles.mainIcon)}>
          {Icon}
        </div>
      )
    }
    return null;
  }

  renderSelect() {
    const { textValue } = this.state;

    const { label, tabIndex, disabled, options } = this.props;
    return (
      <Fragment>
        <label className={styles.label}>{label}</label>
        <div className={styles.input}>
          <span
            className={styles.select}
            tabIndex={tabIndex}
            onClick={() => {if (!disabled) this.setState({showOptions: !this.state.showOptions})}}
          >
          </span>
          <div className={styles.value}>
            <span>{textValue}</span>
            <Icon name={'arrow-down'} color={'#333'} />
          </div>
        </div>
      </Fragment>
    )
  }

  render() {
    const {
      className,
      disabled,
      Icon,
      loading,
    } = this.props;

    const { value } = this.state;

    const cls = classNames(
      styles.field,
      className,
      {
        [`${styles.disabled}`]: disabled,
        [`${styles.hasIcon}`]: Icon || loading,
        [`${styles.has_value}`]: value !== '',
      }
    );

    return (
      <div className={cls} ref={this.setWrapperRef}>
        {this.renderIcon()}
        {this.renderSelect()}
        {this.renderOptions()}
      </div>
    )
  }
}

Select.defaultProps = {
  Icon: false,
  className: '',
  label: '',
  description: '',
  loading: false,
  tabIndex: 0,
  value: null,
  disabled: false,
  options: [],
  onChange: (_) => { },
};

Select.propTypes = {
  Icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element
  ]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  tabIndex: PropTypes.number,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.element
    ]),
    disabled: PropTypes.bool
  })),
  onChange: PropTypes.func
};


export default Select;
