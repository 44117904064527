import React, { Component } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styles from './datepicker.css';
import Calendar from './Calendar'

export default class Picker extends Component {

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      value: null
    }
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return {
        value:  moment(nextProps.value)
      };
    }
    return null;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.visible) {
        this.hidePicker()
      }
    }
  }

  showPicker = () => {
    this.props.onFocus()
    this.setState({ visible: true })
  }
  hidePicker = () => {
    this.props.onBlur()
    this.setState({ visible: false })
  }

  render() {
    const { visible, value } = this.state;
    const {
      minDate,
      maxDate,
      defaultDate,
      numberOfMonth,
    } = this.props;


    return (
      <div ref={this.setWrapperRef}>
        <div className={styles.dateLabels} onClick={this.showPicker} >
          {(value !== null) && <div style={{ display: 'flex', alignItems: 'center' }}>
            {value && <span>{value.format('YYYY-MM-DD')}</span>}
          </div>}
        </div>
        <Calendar
          visible={visible}
          range={false}
          defaultDate={defaultDate}
          minDate={minDate}
          maxDate={maxDate}
          numberOfMonth={numberOfMonth}
          from={value}
          to={null}
          onChange={(range) => {
            this.hidePicker()
            this.setState({ value: range.from })
            this.props.onChange(range && range.from.toDate() || null)
          }}
        />
      </div>
    )
  }
}
Picker.defaultProps = {
  value: null,
  defaultDate: new Date(),
  minDate: null,
  maxDate: null,
  numberOfMonth: 2,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
}
Picker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  defaultDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  numberOfMonth: PropTypes.number,
}
