import React from 'react'
import Code from '../doc/Code/'
import Strong from './index';
import Paragraph from '../Paragraph';

const example = () => (
  <div>
    <Code code={`<Strong>
  Your bank does not support fast payouts.
</Strong>`}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>
          <tr>
            <td>className</td>
            <td>string</td>
          </tr>
          <tr>
            <td>style</td>
            <td>object</td>
          </tr>
          
        </tbody>
      </table>
    </div>

    

    <div className="prev">
      <h3 className="label">Paragraph small</h3>
      <Strong small>What happens now?</Strong>
    </div>
    <div className="prev">
      <h3 className="label">Paragraph medium</h3>
      <Strong>What happens now?</Strong>
    </div>
    <div className="prev">
      <h3 className="label">Paragraph large</h3>
      <Strong large>What happens now?</Strong>
    </div>
    <div className="prev">
      <h3 className="label">Paragraph large</h3>
      <Paragraph>Shankle landjaeger chicken <Strong>short loin porchetta jerky tri-tip</Strong>burgdoggen flank. Jowl prosciutto chuck tenderloin, chicken meatball sirloin burgdoggen shoulder jerky frankfurter pork belly pork. Sausage boudin drumstick jerky capicola hamburger. Swine capicola filet mignon tail short loin bacon venison drumstick. Brisket prosciutto corned beef pork rump sausage turkey kielbasa. Rump ham boudin, picanha hamburger jowl tongue. Pork loin turducken rump, drumstick swine picanha kevin buffalo.</Paragraph>
    </div>
  </div>
)
export default example;