import React from 'react'
import Code from '../doc/Code/'
import ToolTip from '../ToolTip/';
import Loader from '../Loader/';
import List from './index';


const example = () => (
  <div>
    <Code code={`<List
  items={[
    { label: 'Nettolön', value: '100kr'},
    { label: <span>Skatt (30%)</span>, value: '100kr'},
  ]}
/>`}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>


        </tbody>
      </table>
    </div>


    <div className="prev">
      <h3 className="label">List</h3>
      <List
        items={[
          { label: 'Nettolön', key: 'nett' },
          { label: <span>Skatt (30%) </span>, key: 'tax0'},
        ]}
      />
    </div>

    <div className="prev">
      <h3 className="label">List key value</h3>
      <List
        items={[
          { label: 'Nettolön', value: '100kr', key: 'netto1' },
          { label: <span>Skatt (30%) <ToolTip text={'Helpful tip'} /></span>, value: '100kr', key: 'tax2' },
          { label: <div>Nettolön</div>, value: <span><Loader inline small color={'gray'} /> 500</span>, key: 'netto2' }
        ]}
      />
    </div>

    <div className="prev">
      <h3 className="label">List key value small</h3>
      <List
        small
        items={[
          { label: 'Nettolön', value: '100kr', key: 'netto3' },
          { label: <span>Skatt (30%) <ToolTip text={'Helpful tip'} /></span>, value: '100kr', Icon: '', key: 'tax3' },
        ]}
      />
    </div>

  </div>
)
export default example;