import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './boxselector.css'

const Title = ({ children }) => {
  return (
    <div className={styles.title}>
      <div className={styles.child}>
        <div className={styles.bg}>
          <span>
            {children}
          </span>
        </div>
      </div>
    </div>
  )
};

export default class BoxSelector extends Component {

  state = {
    selectedIndex: null,
    value: ''
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      let index = null;
      for (let i = 0; i < nextProps.options.length; i++) {
        if (nextProps.value === nextProps.options[i].value && !nextProps.options[i].disabled) {
          index = i;
          break
        }
      }
      return { value: nextProps.value, selectedIndex: index }
    }
    return null
  }

  render() {
    const { selectedIndex } = this.state;
    const { className, large, small, vertical, responsive, options } = this.props;
    const cls = classNames(
      styles.boxSelector,
      {
        [`${styles.large}`]: large,
        [`${styles.small}`]: small,
        [`${styles.vertical}`]: vertical,
        [`${styles.responsive}`]: responsive,
      },
      className
    );
    return (
      <div className={styles.boxContainer}>
        <ul className={cls}>
          {
            options.map((option, index) => {
              const tabCls = classNames(
                {
                  [`${styles.selected}`]: (index === selectedIndex),
                  [`${styles.disabled}`]: option.disabled,
                },
              );
              return (
                <li
                  key={`box.${option.value}`}
                  className={tabCls}
                  onClick={() => {
                    if (!option.disabled) {
                      this.props.onChange(option.value)
                    }
                  }}
                >
                  {option.title && <Title>{option.title}</Title>}
                  <div className={styles.itemContainer}>
                    <div className={styles.iconContainer}>{option.Icon}</div>
                    <span className={styles.label}>{option.label}</span>
                  </div>

                </li>
              )
            })
          }
        </ul>
        <div className={styles.componentContainer}>
          {
            options.map((option, index) => (
              <div key={`com.${option.value}`}>
                {
                  (selectedIndex === index) && option.Component
                }
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

BoxSelector.defaultProps = {
  className: '',
  large: false,
  small: false,
  vertical: false,
  responsive: true,
  value: '',
  options: [],
  onChange: () => { }
};
BoxSelector.propTypes = {
  className: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  vertical: PropTypes.bool,
  responsive: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    Icon: PropTypes.element,
    Component: PropTypes.element,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })),
  onChange: PropTypes.func
};
