import React, {Component} from 'react'
import Code from '../doc/Code/'
import Checkbox from "./index";

class Example extends Component {

  state = {
    value: false
  };

  render() {
    return (
      <div>
        <Code code={`<Checkbox
        id={'agree'}
        label={}
        description={'I read and agree to the terms and conditions'}
        onChange={()=>{}}/>`}
        />
        <div>
          <h4>Props</h4>
          <table width="100%" className="props-table">
            <tbody>
            </tbody>
          </table>
        </div>

        <div className="prev">
          <h3 className="label">Checkbox</h3>
          <div style={{display: "flex", alignItems: 'space-around', justifyContent: 'space-around', margin: 20}}>
            <Checkbox
              id={'agree'}
              value={this.state.value}
              description={'I read and agree to the terms and conditions'}
              descriptionStyle={{color: this.state.value === true? 'black':'grey'}}
              onChange={() => this.setState({value:!this.state.value})}/>
          </div>
        </div>
      </div>
    );
  }
}

export default Example;
