import React, { Component } from 'react'
import PropTypes from 'prop-types';
import styles from './picker.css';
import Icon from '../Icon/'

export default class Picker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showDropdown: false,
      selectedIndex: 0,
      showIndex: false,
      value: '',
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this._onKeyPressed = this.onKeyPressed.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      let index = 0;
      for (let i = 0; i < nextProps.options.length; i++) {
        if (nextProps.value === nextProps.options[i].value) {
          index = i;
          break
        }
      }
      return { value: nextProps.value, selectedIndex: index }
    }
    return null
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  /**
    * Alert if clicked on outside of element
    */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.showDropdown) {
        this.setState({ showDropdown: false, showIndex: false });
        document.removeEventListener("keydown", this._onKeyPressed);
        this.selectItem(this.state.selectedIndex);
      }
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  mod(n, m) {
    return ((n % m) + m) % m;
  }

  onKeyPressed(e) {

    e.preventDefault();
    // up: 38
    // down: 40
    if (e.keyCode === 38 || e.keyCode === 40) {
      const { selectedIndex } = this.state;
      if (e.keyCode === 38) {
        this.setState({
          showIndex: true,
          selectedIndex: this.mod(selectedIndex + 1, this.props.options.length)
        });
      } else if (e.keyCode === 40) {
        this.setState({
          showIndex: true,
          selectedIndex: this.mod(selectedIndex - 1, this.props.options.length)
        });
      }
    } else if (e.keyCode === 13) {
      this.setState({
        showIndex: false,
        showDropdown: false,
      });
      document.removeEventListener("keydown", this._onKeyPressed);
      this.onChange(this.state.selectedIndex);

    } else {
      this.setState({ showIndex: false });
    }
  }

  onChange = (newIndex) => {
    this.props.onChange(this.props.options[newIndex]);
  }

  selectItem = (index) => {
    this.setState({
      showIndex: false,
      selectedIndex: index,
      showDropdown: false,
    });
    this.onChange(index);
    document.removeEventListener("keydown", this._onKeyPressed);
  }

  toggleDropdown = (e) => {
    e.preventDefault();
    const { showDropdown } = this.state;

    if (!showDropdown) {
      document.addEventListener("keydown", this._onKeyPressed);
    } else {
      document.removeEventListener("keydown", this._onKeyPressed);
      this.setState({ showIndex: false });
    }

    this.setState({ showDropdown: !showDropdown, showIndex: !showDropdown })
  }

  render() {
    const { showIndex, selectedIndex, showDropdown } = this.state;
    const {
      options
    } = this.props;
    return (
      <div className={styles.inputPickerContainer} ref={this.setWrapperRef}>
        <span className={styles.pickerType}>
          <span onClick={this.toggleDropdown} className={styles.pickerLabel}>
            {options[selectedIndex].label}
            {
              showDropdown && <Icon name={'arrow-up'} />
            }
            {
              !showDropdown && <Icon name={'arrow-down'} />
            }
          </span>
          <ul className={`${styles.pickerList} ${showDropdown ? styles.show : ''}`}>
            {
              options.map((item, index) => (
                <li
                  key={`option-${item.value}`}
                  onClick={this.selectItem.bind(this, index)}
                  className={`${(showIndex && index === selectedIndex) ? styles.selected : ""} ${styles.option}`}
                >
                  <span className={styles.title}>{item.label}</span>
                  <span className={styles.desc}>{item.description}</span>
                </li>
              ))
            }
          </ul>
        </span>
      </div>
    )
  }
}

Picker.defaultProps = {
  options: [],
  value: '',
  onChange: () => { },
};

Picker.propTypes = {
  option: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func,
  value: PropTypes.string,
};
