import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styles from './progressbar.css';

class ProgressBar extends Component {

  render() {
    const { percent, className, style } = this.props;
    return (
      <div className={styles.container}>
        <div className={`${styles.progressBar} ${className}`} style={{width: `${percent}%`, ...style}}></div>
      </div>
    )
  }
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  percent: PropTypes.number
};

ProgressBar.defaultProps = {
  className: '',
  style: null,
  percent: 0,

};

export default ProgressBar;
