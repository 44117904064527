import React, { Component } from 'react'
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styles from './datepicker.css';
import Calendar from './Calendar'
import Icon from '../Icon/index';

export default class Range extends Component {

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      value: {
        from: null,
        to: null
      }
    }
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return {
        value: {
          from: (nextProps.value.from) ? moment(nextProps.value.from) : null,
          to: (nextProps.value.to) ? moment(nextProps.value.to) : null
        }
      };
    }
    return null;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.visible) {
        this.hidePicker()
      }
    }
  }

  showPicker = () => {
    this.props.onFocus()
    this.setState({ visible: true })
  }
  hidePicker = () => {
    this.props.onBlur()
    this.setState({ visible: false })
  }

  render() {
    const { visible, value: { from, to } } = this.state;
    const {
      minDate,
      maxDate,
      defaultDate,
      numberOfMonth,
    } = this.props;

    return (
      <div ref={this.setWrapperRef}>

        <div className={styles.dateLabels} onClick={this.showPicker} >
          {(from !== null || to !== null) && <div style={{ display: 'flex', alignItems: 'center' }}>
            {from && <span>{from.format('YYYY-MM-DD')}</span>} <Icon name={'arrow-forward'} style={{ margin: 'auto 5px' }} /> {to && <span>{to.format('YYYY-MM-DD')}</span>}
          </div>}
        </div>

        <Calendar
          visible={visible}
          range={true}
          defaultDate={defaultDate}
          minDate={minDate}
          maxDate={maxDate}
          from={from}
          to={to}
          numberOfMonth={numberOfMonth}
          onChange={(range) => {
            if (range.from !== null && range.to !== null) {
              this.hidePicker()
            }
            this.setState({ value: range })
            this.props.onChange({
              from: range && range.from && range.from.toDate() || null,
              to: range && range.to && range.to.toDate() || null
            })
          }}
        />
      </div>
    )
  }
}
Range.defaultProps = {
  value: {
    from: null,
    to: null,
  },
  defaultDate: new Date(),
  minDate: null,
  maxDate: null,
  numberOfMonth: 2,
  onFocus: () => { },
  onBlur: () => { },
  onChange: () => { },

}
Range.propTypes = {
  value: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
  }),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  defaultDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  numberOfMonth: PropTypes.number,
}
