import React, { Component } from 'react'
import Code from '../doc/Code/'
import Input from './index';
import Fieldset from '../Fieldset';
import Icon from '../Icon';

class Example extends Component {

  state = {
    value: 'String',
    value2: 'String',
    pickerV: 'netto'
  }
  render() {
    return (
      <div>
        <p>An input field must wrapped inside a Fieldset.</p>
        <Code code={`<Fieldset>
  <Input
    value={'hejsa'}
    label={'Namn'}
    error
    letIcon
    pattern
    validate={(value) => {
      return true
    }}
    keyboardType={'numeric'}
    loading
    leftIcon={<Icon name={'mail'} />}

    onChange={() => {}}
    onBlur={() => {}}
    onFocus={() => {}}
    onKeyUp={() => { }}
    onKeyDown={() => { }}
    onSubmit={() => {}}
  />
</Fieldset>`}
        />

        <div>
          <h4>Props</h4>
          <table width="100%" className="props-table">
            <tbody>
              <tr>
                <td>className</td>
                <td>string</td>
                <td></td>
              </tr>
              <tr>
                <td>style</td>
                <td>object</td>
                <td></td>
              </tr>
              <tr>
                <td>label</td>
                <td>string</td>
                <td></td>
              </tr>
              <tr>
                <td>tabIndex</td>
                <td>int</td>
                <td></td>
              </tr>
              <tr>
                <td>readOnly</td>
                <td>bool</td>
                <td></td>
              </tr>
              <tr>
                <td>disabled</td>
                <td>bool</td>
                <td></td>
              </tr>
              <tr>
                <td>Icon</td>
                <td>component</td>
                <td>
                  see <a href="#icons">available icons</a>
                </td>
              </tr>
              <tr>
                <td>error</td>
                <td>bool</td>
                <td></td>
              </tr>
              <tr>
                <td>keyboardType</td>
                <td>string</td>
                <td>one of 'default', 'numeric'</td>
              </tr>
              <tr>
                <td>validate</td>
                <td>func</td>
                <td>retrieves the value and returns true or false based on the input</td>
              </tr>
              <tr>
                <td>format</td>
                <td>string</td>
                <td>mask or format the string</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="prev">
          <h3 className="label">Input</h3>
          <Fieldset>
            <Input
              label={'First name'}
              onChange={value => this.setState({ value })}
              value={this.state.value}
              onSubmit={() => alert('Bam')}
            />
          </Fieldset>
        </div>

        <div className="prev">
          <h3 className="label">Input with left icon</h3>
          <Fieldset>
            <Input
              label={'First name'}
              onChange={value2 => this.setState({ value2 })}
              value={this.state.value2}
              leftIcon={<Icon name={'person'} size={24} />}
            />
          </Fieldset>
        </div>

        <div className="prev">
          <h3 className="label">Input with format</h3>
          <Fieldset>
            <Input
              label={'First name'}
              onChange={value2 => this.setState({ value2 })}
              value={this.state.value2}
              leftIcon={<Icon name={'person'} size={24} />}
            />
          </Fieldset>
        </div>

        <div className="prev">
          <h3 className="label">Input row</h3>
          <Fieldset>
            <Input
              label={'First name'}
              leftIcon={<Icon name={'mail'} />}
            />
            <Input
              label={'Personal number'}
              inputMode="numeric"
              pattern="[0-9]*"
              leftIcon={<Icon name={'mail'} />}
            />
            <Input
              label={'Person loading'}
              inputMode="numeric"
              pattern="[0-9]*"
              loading
              leftIcon={<Icon name={'mail'} />}
            />
            <Input
              label={'Person warning'}
              inputMode="numeric"
              pattern="[0-9]*"
              warning={'This is a warning'}
              leftIcon={<Icon name={'mail'} />}
              onChange={value2 => this.setState({ value2 })}
              value={this.state.value2}
            />
            <Input
              label={'Person error'}
              inputMode="numeric"
              pattern="[0-9]*"
              error={'This is an error'}
              leftIcon={<Icon name={'mail'} />}
              onChange={value2 => this.setState({ value2 })}
              value={this.state.value2}
            />
            <Input
              label={'Phone'}
              inputMode="tel"
              type={'tel'}
              leftIcon={<Icon name={'phone'} size={22} />}
            >
              <Input.Picker
                onChange={(v) => this.setState({ pickerV: v.value})}
                options={[
                  { label: 'Brutto', description: 'Med skatt', value: 'brutto' },
                  { label: 'Netto', description: 'Utan skatt', value: 'netto' }
                ]}
                value={this.state.pickerV}
              />
            </Input>
            <Fieldset.Row>
              <Input
                label={'First name'}
                leftIcon={<Icon name={'write'} />}
              />
              <Input
                label={'Disabled field'}
                disabled
              />
            </Fieldset.Row>
          </Fieldset>
        </div>


      </div>
    )
  }
}
export default Example;
