import React, { Component } from 'react'
import Code from '../doc/Code/'
import Radio from './index'

class Example extends Component {

  state = {
    value: null
  };
  render() {
    return (
      <div>
        <Code code={`<Radio
  option={[
    {
      label: 'Företag 1',
      description: 'Din roll',
      value: 'org1',
    }, {
      label: 'Företag 2',
      description: 'Din roll',
      value: 'org2',
    }
  ]}
  value={'org1'}
  onChange={value => {

  }}
/>`}
        />

        <div>
          <h4>Props</h4>
          <table width="100%" className="props-table">
            <tbody>


            </tbody>
          </table>
        </div>


        <div className="prev">
          <h3 className="label">Radio</h3>
          <Radio
            options={[
              {
                label: 'Företag 1',
                description: (
                  <div>
                    <span>OrgNr: 5591129670</span><br />
                    <span>Din roll: Styrelseledamot</span>
                  </div>
                ),
                value: 'org1'
              }, {
                label: 'Företag 2',
                description: 'Din roll',
                value: 'org2'
              }
            ]}
            value={this.state.value}
            onChange={value => {
              console.log({ value })
              this.setState({ value })
            }}
          />
        </div>
      </div>
    );
  }
}

export default Example;