import React, { Component } from 'react'

import Code from '../doc/Code/'
import Dialog from './index';
import Button from '../Button/'

export default class example extends Component {
  state = {
    showModal: false,
  }
  render() {
    const { showModal } = this.state;
    return (
      <div>
        <Code code={`<Dialog
  show={showDialog}
  disableBackdropClick={false}
  disableEscapeKeyDown={false}
  onBackdropClick={() => console.log("close")}
  onEscapeKeyDown={() => console.log("close")}
  onClose={() => {}}
  onOpen={() => {}}
  className={''}
  style={{}}
  >
    <span>Here is the content</span>
</Dialog>`}
        />

        <div>
          <h4>Props</h4>
          <table width="100%" className="props-table">
            <tbody>


            </tbody>
          </table>
        </div>


        <div className="prev">
          <h3 className="label">Dialog</h3>
          
          <Button onClick={() => this.setState({ showModal: true })}>Press here</Button>
        </div>
        <Dialog
          show={showModal}
          onBackdropClick={() => this.setState({ showModal: false })}
        >
          <span>Here is the content</span>
        </Dialog>


      </div>
    )
  }
}

