import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../config.css'
import styles from './title.css';

/*
 * THE DEFAULT BUTTON 
 */
const DefaultTitle = ({ small, style, margins, className, children, line }) => {

  const cls = classNames(
    styles.title,
    {
      [`${styles.small}`]: small,
      [`${styles.hasLine}`]: line,
      [`${styles.defaultMargins}`]: margins
    },
    className
  );
  return (
    <h1 style={style} className={cls}>
      { children }
    </h1>
  )
}
DefaultTitle.defaultProps = {
  className: '',
  style: null,
  line: false,
  small: false,
  margins: true,
};
DefaultTitle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  small: PropTypes.bool,
  line: PropTypes.bool,
  margins: PropTypes.bool,
};

const TitleWithLine = (props) => (
  <DefaultTitle {...props} line  />
)

class Title extends Component {
  static Line = TitleWithLine;
  render() {
    return <DefaultTitle {...this.props} />
  }
}
export default Title;