import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Loader from '../Loader/'
import styles from './button.css'

/*
 * THE DEFAULT BUTTON
 */
const DefaultButton = (props) => {
  const { loading, disabled, responsive, Icon, primary, disabledOnClick, onClick } = props
  const cls = classNames(
    styles.btn,
    {
      [`${styles.disabled}`]: disabled,
      [`${styles.responsive}`]: responsive,
      [`${styles.hasIcon}`]: Icon,
      [`${styles.primary}`]: primary,
      [`${styles.loading}`]: loading,
    },
    props.className
  );
  return (
    <div style={props.style} onClick={(disabled || loading) ? disabledOnClick : onClick} className={cls}>
      { loading && <div className={styles.loadingContainer}>
        <Loader color={ primary ? '#fff' : '#000'} />
      </div>}
      <div className={styles.btnLabel} style={{ opacity: loading ? 0 : 1 }}>
        { props.children }
        { Icon }
      </div>
    </div>
  )
};

DefaultButton.defaultProps = {
  className: '',
  disabledOnClick: () => {},
  onClick: () => {},
  style: null,
  disabled: false,
  responsive: false,
  Icon: false,
  primary: false,
  loading: false,
  children: null
};

DefaultButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabledOnClick: PropTypes.func,
  disabled: PropTypes.bool,
  responsive: PropTypes.bool,
  primary: PropTypes.bool,
  loading: PropTypes.bool,
  Icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.any])
};


const PrimaryButton = (props) => (
  <DefaultButton {...props} primary  />
);

class Button extends Component {

 static Primary = PrimaryButton;
 static Secondary = DefaultButton;
 static Success = DefaultButton;

  render() {
    return <DefaultButton {...this.props} />
  }
}
export default Button;
