import React from 'react'
import Code from '../doc/Code';
import Alert from './index';
import Icon from '../Icon/';

const example = () => (
  <div>
<Code code={`<Alert.Warning>
  Your bank does not support fast payouts.
</Alert.Warning>`}
/>

    <h3>Alert</h3>
    <Alert>Your bank does not support fast payouts. The money will be paid out the next business day.</Alert>

    <h3>Alert</h3>
    <Alert alignLeft Icon={<Icon name={'info'} />}>Your bank does not support fast payouts. <a href="#">The money will be paid out the next</a> business day.</Alert>

    <h3>Alert.Warning</h3>
    <Alert.Warning>Your bank does not support fast payouts. The money will be paid out the next business day.</Alert.Warning>

    <h3>Alert.Error</h3>
    <Alert.Error>Could not find any valid recipients in the file</Alert.Error>

    <h3>Alert.Success</h3>
    <Alert.Success>Payment successful'</Alert.Success>
  </div>
)
export default example;