import React from 'react';

import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './boxaction.css';
import '../config.css'


const BoxAction = ({ className, Icon, disabled, label, description, extra, style, onClick }) => {
  const cls = classNames(
    styles.boxAction,
    {
      [`${styles.disabled}`]: disabled,
      [`${styles.link}`]: onClick
    },
    className
  );

  return (
    <div className={cls} onClick={onClick} style={style}>
      {
        Icon && (
          <div className={styles.iconContainer}>
            { Icon }
          </div>
        )
      }
      <div className={styles.content}>
        <span className={styles.label}>{label}</span>
        <p className={styles.description}>{description}</p>
      </div>
      {
        extra && (
          <div className={styles.extraContainer}>
            { extra }
          </div>
        )
      }
    </div>

  )
};

BoxAction.defaultProps = {
  className: '',
  label: '',
  description: '',
  Icon: null,
  disabled: false,
  extra: null,
  onClick: null,
  style: null,
};

BoxAction.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  Icon: PropTypes.element,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};


export default BoxAction;
