import React, { Component } from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../config.css';
import styles from './paragraph.css';

export default class Paragraph extends Component {
  render() {
    const { children, className, style } = this.props;
    const cls = classNames(
      styles.paragraph,
      className,
      {
        [`${styles.xsmall}`]: this.props.xsmall,
        [`${styles.small}`]: this.props.small,
        [`${styles.large}`]: this.props.large,
        [`${styles.defaultMargins}`]: this.props.margins,
      }
    );
    return (
      <p className={cls} style={style}>
        {children}
      </p>
    )
  }
}

Paragraph.defaultProps = {
  className: '',
  style: null,
  xsmall: false,
  medium: false,
  large: false,
  small: false,
  margins: true
};
Paragraph.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  xsmall: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  margins: PropTypes.bool
};