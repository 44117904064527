import React from 'react'
import Code from '../doc/Code/'
import Paragraph from './index';


const example = () => (
  <div>
    <Code code={`<Paragraph>
  Your bank does not support fast payouts.
</Paragraph>`}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>
          <tr>
            <td>className</td>
            <td>string</td>
          </tr>
          <tr>
            <td>style</td>
            <td>object</td>
          </tr>
          
        </tbody>
      </table>
    </div>

    

    <div className="prev">
      <h3 className="label">Paragraph small</h3>
      <Paragraph small>Fill in the amount and description so that a link is generated for your pay request.</Paragraph>
    </div>
    <div className="prev">
      <h3 className="label">Paragraph medium</h3>
      <Paragraph>Fill in the amount and description so that a link is generated for your pay request.</Paragraph>
    </div>
    <div className="prev">
      <h3 className="label">Paragraph large</h3>
      <Paragraph large>Fill in the amount and description so that a link is generated for your pay request.</Paragraph>
    </div>


  </div>
)
export default example;