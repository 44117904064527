import React, { Component } from 'react'

export default class index extends Component {
  render() {
    return (
      <div>
        {
        /*<Tabs
          tabs={[
            {
              label: 'Test',
              Component: <span>Test</span>,
              path: '/settings/integrations/'
            }
          ]}
        />
        */
        }
      </div>
    )
  }
}
