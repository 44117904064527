import React from 'react'
import Code from '../doc/Code'
import Icon from '../Icon';
import BoxAction from './index';

const example = () => (
  <div>
    <Code code={`<BoxAction
  label={'Verify your identity'}
  description={'Submit a quick photo of yourself and your ID'}
  Icon={<Icon name={'person'} size={30} />}
  extra={<Icon name={'arrow-forward'} size={25} />}
  onClick={() => { }}
  disabled
  style={{  marginBottom: 16 }}
/>`}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>


        </tbody>
      </table>
    </div>


    <div className="prev">
      <h3 className="label">Link</h3>
      <div style={{ display: 'block', width: '100%'}}>
        <BoxAction
          label={'Verify your identity'}
          description={'Submit a quick photo of yourself and your ID'}
          Icon={<Icon name={'person'} size={30} />}
          extra={<Icon name={'arrow-forward'} size={25} />}
          onClick={() => { }}
          disabled
          style={{  marginBottom: 16 }}
        />
        <BoxAction
          label={'Verify your identity'}
          description={'Submit a quick photo of yourself and your ID'}
          Icon={<Icon name={'person'} size={30} />}
          extra={<Icon name={'arrow-forward'} size={25} />}
          onClick={() => { }}
          style={{  marginBottom: 16 }}
        />
        <BoxAction
          label={'Verify your identity'}
          description={'Submit a quick photo of yourself and your ID'}
          Icon={<Icon name={'person'} size={30} />}
          extra={<Icon name={'arrow-forward'} color={'#219929'} size={25} />}
        />
      </div>
    </div>

  </div>
)
export default example;