import React from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './contextmenu.css'
import '../config.css'


const Separator = () => (
  <li className={styles.separator} />
)


const Item = ({ children, Icon, className, style, onClick }) => {
  const cls = classNames(
    styles.item,
    className,
    {
      [`${styles.hasLink}`]: onClick,
      [`${styles.hasIcon}`]: Icon
    }
  );
  return (
    <li className={cls} style={style} onClick={onClick}>
      {Icon && (
        <div className={styles.iconContainer}>
          {Icon}
        </div>
      )}
      {children}
    </li>
  )
}

Item.defaultProps = {
  className: '',
  style: null,
  onClick: null,
  children: null,
  Icon: null
}

Item.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.oneOfType([PropTypes.func]),
  children: PropTypes.any,
  Icon: PropTypes.element
}


class ContextMenu extends React.Component {
  constructor(props) {
    super(props)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.visible && !prevProps.visible) {
      document.addEventListener('click', this.handleClickOutside)
    }
    if (!this.props.visible && prevProps.visible) {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      event.preventDefault()
      this.props.onBlur()
    }
  }

  render() {
    const { children, visible, className, style, arrow } = this.props
    const cls = classNames(
      styles.menu,
      {
        [`${styles.visible}`]: visible,
        [`${styles.arrow}`]: arrow
      },
      className
    )
    return (
      <ul style={style} ref={this.setWrapperRef} className={cls}>
        {children}
      </ul>
    )
  }
}

ContextMenu.Item = Item;
ContextMenu.Separator = Separator;

ContextMenu.defaultProps = {
  className: '',
  style: null,
  children: null,
  visible: false,
  arrow: false,
  onBlur: () => {}
}
ContextMenu.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
  visible: PropTypes.bool,
  arrow: PropTypes.bool,
  onBlur: PropTypes.func
}

export default ContextMenu;
