import React from 'react';
import Icon from '../Icon/index'
import css from './progressBlock.css'
import PropTypes from "prop-types";

const ProgressBlock = ({steps, activeColor, inactiveColor, currentStep}) => {
  return (
    <div className={css.container}>
      {steps.map((step, index) => (
        <div className={css.blockContainer}
             key={`step-${index}`}
        >
          <p className={`${css.title}${currentStep === index? ` ${css.active}` :''}`}
             style={{
               color: currentStep === index ? activeColor : inactiveColor,
             }}>{step.title} <Icon className={css.icon}
                                   style={{marginLeft: 4}}
                                   size={16}
                                   name={step.iconName}/>
          </p>
          <div className={css.block}
               style={{
                 backgroundColor: currentStep >= index + 1 ? activeColor
                   : inactiveColor
               }}>
            {currentStep === index &&
            <div className={css.value} style={{backgroundColor: activeColor}}/>
            }
          </div>
        </div>
      ))
      }
    </div>
  )
};


ProgressBlock.propTypes = {
  steps: PropTypes.array,
  activeColor: PropTypes.string,
  currentStep: PropTypes.number
}

export default ProgressBlock;
