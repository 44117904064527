import React, { Component } from 'react'
import styles from './menu.css';
import '../config.css'

const Tab = ({ children }) => {
  const props = { activeClassName: styles.active };
  const child = typeof children === 'function' ? children(props) : children
  return (
    <ul className={styles.tabMenu}>
      {child}
    </ul>
  )
};
const Item = ({ children }) => {
  const props = { activeClassName: styles.active };
  if (typeof children === 'function') {
    return (
      <li>{children(props)}</li>
    )
  }
  return (
    <li>{children}</li>
  )
}
export default class Menu extends Component {
  static Tab = Tab;
  static Item = Item;
  render() {
    return (
      <div>

      </div>
    )
  }
}
