import React from 'react'
import Code from '../doc/Code/'
import Table from './index'
const data = [
  { id: 0, paid_at: '2019-05-31 12:00', amount: 200 },
  { id: 1, paid_at: '2019-04-31 12:00', amount: 200 },
  { id: 2, paid_at: null, amount: 200 },
  { id: 3, paid_at: '2019-05-31 12:00', amount: 200 },
];
const example = () => (
  <div>
    <Code code={`<Table
  keyExtractor={(item, index) => item.id}
  data={data}
  onClick={props => this.setState({ showModal:true })}
  columns={[{
    Header: null,
    id: 'is_active',
    headerStyle: { flex: '0 0 15px' },
    style: { flex: '0 0 15px' }
  }, {
      Header: 'Invoice number',
      Cell: props => props.id,
      id: 'icon',
  }, {
      Header: t('Paid'),
      Cell: props => (props.paid_at === null) ?
        <span style={{color:'red'}}>Not paid</span> :
        <span>format(props.paid_at)</span>,
      id: 'datew',
  }, {
      Header: <span className="text-align-right">To pay</span>,
      Cell: props => <span className="text-align-right">200kr</span>,
      id: 'amount',
  }]}
/>`}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>
          <tr>
            <td>selectable</td>
            <td>bool</td>
            <td>makes it possible to select multiple rows</td>
          </tr>
          <tr>
            <td>keyExtractor</td>
            <td>func</td>
            <td>(item, index) => 'key'</td>
          </tr>
          <tr>
            <td>data</td>
            <td>array</td>
            <td></td>
          </tr>
          <tr>
            <td>onClick</td>
            <td>func</td>
            <td></td>
          </tr>
          <tr>
            <td>columns</td>
            <td>array</td>
            <td>
              Supports: Header, Cell, id, headerRowSpan, className, style, headerClassName, headerStyle
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <div className="prev">
      <h3 className="label">Table</h3>
      <Table
        keyExtractor={(item, index) => item.id}
        data={data}
        onClick={props => {}}
        columns={[{
          Header: null,
          id: 'is_active',
          headerStyle: { flex: '0 0 15px' },
          style: { flex: '0 0 15px' }
        }, {
          Header: 'Invoice number',
          Cell: props => props.id,
          id: 'icon',
        }, {
          Header: 'Paid',
          Cell: props => (props.paid_at === null) ?
            <span style={{ color: 'red' }}>Not paid</span> :
            <span>{props.paid_at}</span>,
          id: 'datew',
        }, {
          Header: <span className="text-align-right">To pay</span>,
          Cell: props => <span className="text-align-right">{props.amount}kr</span>,
          id: 'amount',
        }]}
      />
    </div>

    <div className="prev">
      <h3 className="label">Table</h3>
      <Table
        selectable={true}
        keyExtractor={(item, index) => item.id}
        data={data}
        columns={[{
          Header: null,
          id: 'is_active',
          headerStyle: { flex: '0 0 15px' },
          style: { flex: '0 0 15px' }
        }, {
          Header: 'Invoice number',
          Cell: props => props.id,
          id: 'icon',
        }, {
          Header: 'Paid',
          Cell: props => (props.paid_at === null) ?
            <span style={{ color: 'red' }}>Not paid</span> :
            <span>{props.paid_at}</span>,
          id: 'datew',
          onClick: () => alert('Hejsvejs')
        }, {
          Header: <span className="text-align-right">To pay</span>,
          Cell: props => <span className="text-align-right">{props.amount}kr</span>,
          id: 'amount',
        }]}
      />
    </div>


  </div>
)
export default example;
