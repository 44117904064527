import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './icon.css';
import CustomIcon from './CustomIcon';
import { customIcons } from './customIcons';
import './icon.css'


const IconMap = {
  'add': 'ion-md-add',
  'alert': 'ion-md-alert',
  'help': 'ion-md-help',
  'help-circle': 'ion-md-help-circle',
  'help-circle-outline': 'ion-md-help-circle-outline',
  'card': 'ion-ios-today',
  'cog': 'ion-md-settings',
  'create': 'ion-md-create',
  'cloud-upload': 'ion-md-cloud-upload',
  'credit-card': 'ion-ios-card',
  'calendar': 'ion-md-calendar',
  'unlock': 'ion-md-unlock',
  'lock': 'ion-md-lock',
  'arrow-up': 'ion-ios-arrow-up',
  'arrow-down': 'ion-ios-arrow-down',
  'arrow-back': 'ion-ios-arrow-back',
  'arrow-forward': 'ion-ios-arrow-forward',
  'help-buoy': 'ion-md-help-buoy',
  'mail': 'ion-md-mail',
  'mail-unread': 'ion-md-mail-unread',
  'phone': 'ion-md-phone-portrait',
  'person': 'ion-md-person',
  'people': 'ion-md-people',
  'write': 'ion-ios-create',
  'document': 'ion-md-document',
  'link': 'ion-md-link',
  'cash': 'ion-md-cash',
  'close-circle': 'ion-md-close-circle',
  'business': 'ion-md-business',
  'checkmark-circle': 'ion-ios-checkmark-circle',
  'trash': 'ion-md-trash',
  'hourglass': 'ion-ios-hourglass',
  'checkmark': 'ion-ios-checkmark',
  'search': 'ion-md-search',
  'clipboard': 'ion-md-clipboard',
  'images': 'ion-md-images',
  'download': 'ion-md-download',
  'warning': 'ion-ios-warning',
  'info': 'ion-md-information-circle-outline',
  'flag': 'ion-md-flag',
  'globe': 'ion-md-globe',
  'filter': 'ion-md-funnel',
  'house': 'ion-md-home',
  'close': 'ion-ios-close',
  'bell': 'ion-md-notifications',
  'pointer':'fa-mouse-pointer'
};

const Icon = ({ name, className, style, color, size }) => {

  const cls = classNames(
    styles['giga-icon'],
    IconMap[name],
    className
  );
  const sts = {
    ...style,
    fontSize: size ? size : null,
    color: color ? color : 'currentColor',
  };

  if (customIcons[name]) {
    return <CustomIcon name={name} size={size} color={color} className={className} style={sts} />
  }

  return (
    <i style={sts} className={cls} />
  )
};

Icon.defaultProps = {
  className: '',
  style: null,
  size: 17,
  color: 'currentColor',
  name: 'help'
};
Icon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
  color: PropTypes.string,
  name: PropTypes.string,
};

export default Icon;
