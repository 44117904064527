import React from 'react'
import Code from '../doc/Code/'
import Title from './index';


const example = () => (
  <div>
    <Code code={`<Title.Line>
  Your bank does not support fast payouts.
</Title.Line>`}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>
          <tr>
            <td>className</td>
            <td>string</td>
          </tr>
          <tr>
            <td>style</td>
            <td>object</td>
          </tr>
          <tr>
            <td>small</td>
            <td>bool</td>
          </tr>
          <tr>
            <td>margin</td>
            <td>bool</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="prev">
      <h3 className="label">Title</h3>
      <Title>Your company name</Title>
    </div>

    <div className="prev">
      <h3 className="label">Title with line</h3>
      <Title.Line>Your company name</Title.Line>
    </div>

    <div className="prev">
      <h3 className="label">Title small</h3>
      <Title small>New payout</Title>
    </div>

  </div>
)
export default example;