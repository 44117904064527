import React, { Component } from 'react'
import moment from 'moment-timezone';
import Code from '../doc/Code/'
import Fieldset from '../Fieldset/index'
import Icon from '../Icon/index'
import Select from './index'


class Example extends Component {

  state = {
    value1: null,
    value2: {
      from: moment().toDate(),
      to: null,
    }
  }

  render() {
    const { value1, value2 } = this.state;
    return (
      <div>
        <Code code={``}
        />

        <div>
          <h4>Props</h4>
          <table width="100%" className="props-table">
            <tbody>


            </tbody>
          </table>
        </div>


        <div className="prev">
          <h3 className="label">Select</h3>
          <Fieldset>
            <Select
              value={'sv'}
              label={'Namn'}
              Icon={<Icon name={'globe'} size={23} />}
              onChange={value2 => {
                this.setState({ value2 })
              }}
              onBlur={() => { }}
              onFocus={() => { }}
              options={[
                { value: 'sv', label: 'Swedish' },
                { value: 'en', label: 'English', icon: <Icon name={'globe'} color={'#A8A8A8'} size={23} />},
                { value: 'fr', label: 'French', disabled: true},
                { value: 'ge', label: 'German', description: 'German needs an explanatory text'},
                { value: 'du', label: 'Dutch', description: 'Dutch def. needs an explanation', icon: <Icon name={'globe'} color={'#A8A8A8'} size={23} />},
                { value: 'de', label: 'Danish', description: 'No, you can\'t choose danish.', disabled: true},
              ]}
            />
          </Fieldset>
        </div>



      </div>
    )
  }
}

export default Example;
