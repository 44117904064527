import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import '../config.css';
import styles from './link.css';


const Link = ({ children, className, style, primary, bold, onClick }) => {
  const cls = classNames(
    styles.a,
    {
      [`${styles.primary}`]: primary,
      [`${styles.bold}`]: bold
    },
    className
  );

  return (
    <span style={style} onClick={onClick} className={cls}>{children}</span>
  )
};

Link.defaultProps = {
  className: '',
  style: null,
  children: null,
  onClick: () => {},
  primary: false,
  bold: false
};

Link.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  children: PropTypes.any,
  primary: PropTypes.bool,
  bold: PropTypes.bool
};

export default Link
