import React from 'react'
import Code from '../doc/Code/'
import Menu from './index';

const example = () => (
  <div>
    <Code code={`<Menu.Tab>
  <Menu.Item>
    <a aria-current="page" href="/settings/">Integrationer</a>
  </Menu.Item>
</Menu.Tab>`}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>
          <tr>
            <td>className</td>
            <td>string</td>
          </tr>
          <tr>
            <td>style</td>
            <td>object</td>
          </tr>

        </tbody>
      </table>
    </div>



    <div className="prev">
      <h3 className="label">Menu.Tab</h3>
      <Menu.Tab>
        <Menu.Item>
          {props => (
            <a className={props.activeClassName} ariaCurrent="page" href="#menu">Bankuppgifter</a>
          )}
        </Menu.Item>
        <Menu.Item>
          {props => (
            <a aria-current="page" href="#menu">Integrationer</a>
          )}
        </Menu.Item>
      </Menu.Tab>
    </div>

    <div className="prev">
      <h3 className="label">Menu.Tab</h3>
      <Menu.Tab>
        {props => (
          <React.Fragment>
            <Menu.Item>
              <a className={props.activeClassName} ariaCurrent="page" href="#menu">Bankuppgifter</a>
            </Menu.Item>
            <Menu.Item>
              <a className={props.activeClassName} ariaCurrent="page" href="#menu2">Bankuppgifter</a>
            </Menu.Item>
          </React.Fragment>
        )}
      </Menu.Tab>
    </div>

    <div style={{ float: 'left' }}>
      <Code code={`<Menu.Tab>
  <Menu.Item>
    { props => (
      <NavLink activeClassName={props.activeClassName} exact to="/settings/">General</NavLink>
    )}
  </Menu.Item>
</Menu.Tab>`}
      />
    </div>


  </div>
)
export default example;