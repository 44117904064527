import React, { Children } from 'react';

const Context = React.createContext();


const gigaTheme = {

}

const defaultTheme = {
  'primary': '#2547B6',
  'primary-fill': '#4A66C2',
  'primary-fill-light': '#F1F1FA',
}

const applyTheme = (colors) => {
  Object.keys(colors).map(key => {
    const value = colors[key];
    document.documentElement.style.setProperty(`--${key}`, value);
  });
};


const ThemeProvider = ({ children, theme }) => {
  const colors = { ...defaultTheme, ...theme };
  applyTheme(colors)
  return (
    <Context.Provider value={colors}>
      {children}
    </Context.Provider>
  )
}
export default ThemeProvider