import React, { Component } from 'react';
import PropTypes from 'prop-types'
import '../config.css'
import styles from './tableStyle.css'
class Table extends Component {

  state = {
    selectRows: [],
    data: [],
  };


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.data !== prevState.data) {
      return { data: nextProps.data };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      //Perform some operation here
      const selectRows = this.state.selectRows.filter(r => this.props.data.indexOf(r) > 0);
      this.setState({
        selectRows
      });
      this.props.onChange(selectRows);
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data
    });
  }

  selectRow = (i) => {

    const { selectRows } = this.state;
    const index = selectRows.indexOf(i);

    let newState = []
    if (index < 0) newState = [...selectRows, i];
    else newState = selectRows.filter(ind => ind !== i);

    this.setState({
      selectRows: newState
    });

    this.props.onChange(newState);

  };


  renderCell(Cell, value) {
    if (typeof Cell === 'string') {
      return Cell;
    } else if (typeof Cell === 'function') {
      return Cell(value)
    } else {
      return Cell;
    }
  }

  handleChange = (i) => {
    const { selectRows } = this.state;
    const index = selectRows.indexOf(i);

    let newState = [];
    if (index < 0) newState = [...selectRows, i];
    else newState = selectRows.filter(ind => ind !== i);

    this.setState({
      selectRows: newState
    });

    this.props.onChange(newState);
  };

  selectAll = () => {
    const { selectRows, data } = this.state;

    let newState = [];
    if (selectRows.length !== data.length) {
      newState = data;
    }

    this.setState({
      selectRows: newState
    });

    this.props.onChange(newState);
  };

  handleClick = (row, e) => {
    if (e.target.type !== 'checkbox') {
      e.preventDefault();
      this.props.onClick(row);
    }
  };

  render() {
    const { selectRows, data } = this.state;
    const { columns, keyExtractor, selectable, onClick } = this.props;
    const clickable = (onClick !== undefined);
    const tableClass = clickable ? styles.clickable : '';

    let checkbox = null;
    if (selectable) {
      const checked = (selectRows.length === data.length);
      checkbox = <div className={`${styles.tableCell} ${styles.check}`}><input type="checkbox" value="1" onChange={this.selectAll.bind(this)} checked={checked} /></div>;
    }
    return (
      <React.Fragment>
        <div className={`${styles.table} ${tableClass}`}>
          <div className={styles.thead}>
            {checkbox}
            {
              columns.map((colum) => (
                <div className={`${styles.tableCell} ${colum.headerClassName}`} style={colum.headerStyle} key={colum.id}>{this.renderCell(colum.Header)}</div>
              ))
            }
          </div>
          <div className={styles.tableBody}>
            {
              data.map((row, rowIndex) => {
                let checkbox = null;
                if (selectable) {
                  const checked = (selectRows.indexOf(row) >= 0);
                  checkbox = <div className={`${styles.tableCell} ${styles.check}`}><input type="checkbox" value="1" onChange={this.handleChange.bind(this, row)} checked={checked} /></div>;
                }

                return (
                  <div className={styles.tableRow} key={keyExtractor(row, rowIndex)} onClick={clickable ? this.handleClick.bind(this, row) : undefined}>
                    {checkbox}
                    {
                      columns.map((colum, columIndex) => {

                        const columnOnClick = colum.onClick;
                        const columnClickable = (columnOnClick !== undefined);
                        const columnClass = columnClickable ? styles.clickable : '';

                        return (
                          <div
                            key={`td-${columIndex}-${keyExtractor(row, rowIndex)}`}
                            className={`${styles.tableCell} ${colum.className} ${columnClass}`}
                            style={colum.style}
                            onClick={columnClickable ? () => columnOnClick(row) : undefined}
                          >
                            {this.renderCell(colum.Cell, row)}
                          </div>
                        )
                      }
                      )
                    }
                  </div>
                )

              })
            }
          </div>

        </div>
      </React.Fragment>
    )
  }
}

Table.propTypes = {
  selectable: PropTypes.bool,
  keyExtractor: PropTypes.func,
  onClick: PropTypes.func,
  data: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
      Cell: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      headerRowSpan: PropTypes.number,
      className: PropTypes.string,
      style: PropTypes.object,
      headerClassName: PropTypes.string,
      headerStyle: PropTypes.object,
      onClick: PropTypes.func,

    })
  ),
  onChange: PropTypes.func,


}
Table.defaultProps = {
  selectable: false,
  keyExtractor: (item, index) => index,
  data: [],
  onClick: undefined,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: null,
      Cell: null,
      id: '',
      headerRowSpan: 1,
      className: '',
      style: {},
      headerClassName: '',
      headerStyle: {},
      onClick: undefined,
    })
  ),
  onChange: () => { },
}

export default Table;
