import React from 'react'
import Code from '../doc/Code/'
import Card from './index'
import Paragraph from '../Paragraph'
import List from '../List'

const example = () => (
  <div>
    <Code code={``}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>

        </tbody>
      </table>
    </div>

    <div className="prev">
      <h3 className="label">Card with text</h3>
      <Card>
        <Paragraph>Shankle landjaeger chicken short loin porchetta jerky tri-tip burgdoggen flank. Jowl prosciutto chuck tenderloin, chicken meatball sirloin burgdoggen shoulder jerky frankfurter pork belly pork. Sausage boudin drumstick jerky capicola hamburger. Swine capicola filet mignon tail short loin bacon venison drumstick. Brisket prosciutto corned beef pork rump sausage turkey kielbasa. Rump ham boudin, picanha hamburger jowl tongue. Pork loin turducken rump, drumstick swine picanha kevin buffalo.</Paragraph>
      </Card>
    </div>
    
    <div className="prev">
      <h3 className="label">Card with list</h3>
      <Card>
        <List
          items={[
            { label: 'Nettolön' },
            { label: <span>Skatt (30%) </span> },
          ]}
        />
      </Card>
    </div>

  </div>
)
export default example;