import React from 'react'
import PropTypes from 'prop-types'
import css from './tooltip.css';
import Icon from '../Icon/';


const HelpTip = ({ text, width, label, style }) => (
  <span className={css.toolTipContainer} style={style}>
    <span className={css.toolTip} style={{ width }}><p>{text}</p></span>
    {label}
  </span>
);

HelpTip.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  style: PropTypes.object
};

HelpTip.defaultProps = {
  width: 'auto',
  text: '',
  label: <Icon name={"help-circle"} size={14} />,
  style: null
};

export default HelpTip;
