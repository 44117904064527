import React, { Component } from 'react';
import PropTypes from 'prop-types'

import styles from './dialog.css';
class Dialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cls: '',
      show: false,
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleEscClick = this.handleEscClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.show !== prevState.show) {
      return {
        show: nextProps.show,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show !== this.props.show) {
      const { show } = this.props;
      if (show) {
        this.setState({
          cls: styles.showModal,
        });
        this._onShow();
      } else {
        this.setState({
          cls: styles.hideModal,
        });
        this._onHide();
        setTimeout(() => {
          this.setState({
            cls: ''
          });
        }, 300);
      }
    }
  }

  componentDidMount() {
    const { show } = this.props;
    if (show) {
      this.setState({
        cls: styles.hideModal,
      });
      this._onShow();
    }
  }

  componentWillUnmount(){
    document.body.classList.remove(styles.modalOpen);
  }

  _onShow() {
    if (!this.props.disableBackdropClick) {
      document.addEventListener('click', this.handleClickOutside);
    }
    if (!this.props.disableEscapeKeyDown) {
      document.addEventListener('keydown', this.handleEscClick)
    }
    document.body.classList.add(styles.modalOpen);
    this.props.onOpen()
  }

  _onHide() {
    if (!this.props.disableBackdropClick) {
      document.removeEventListener('click', this.handleClickOutside);
    }
    if (!this.props.disableEscapeKeyDown) {
      document.removeEventListener('keydown', this.handleEscClick)
    }
    document.body.classList.remove(styles.modalOpen);
    this.props.onClose()
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onBackdropClick();
      event.preventDefault();
    }
  }

  handleEscClick(event) {
    if (event.keyCode === 27) {
      this.props.onEscapeKeyDown()
    }
  }
  render() {
    const { cls } = this.state;
    const { children, className, style } = this.props;
    if (cls === '') return null;
    return (
      <div className={`${styles.modalOverlay} ${cls}`}>
        <div className={`${styles.modalContainer} ${className}`} ref={this.setWrapperRef} style={style}>
          {children}
        </div>
      </div>
    )
  }
}

Dialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,

  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,

  onEscapeKeyDown: PropTypes.func,
  onBackdropClick: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Dialog.defaultProps = {
  children: null,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
  onEscapeKeyDown: () => { },
  onBackdropClick: () => { },
  onClose: () => { },
  onOpen: () => { },
  className: '',
  style: null
};

export default Dialog;
