import React from 'react';
import PropTypes from 'prop-types';

import styles from './icon.css';
import { customIcons } from './customIcons';


const CustomIcon = ({ name, className, style, color, size }) => {

  return (
    <i className={`${styles.customGigaIcon} ${className}`} style={style}>
      { customIcons[name]({ color, size }) }
    </i>
  )
};


CustomIcon.defaultProps = {
  size: 14,
  color: '#333',
  className: '',
  style: null
};

CustomIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};


export default CustomIcon;
