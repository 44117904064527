import React, { Component } from 'react'
import moment from 'moment-timezone';
import Code from '../doc/Code/'
import Fieldset from '../Fieldset/index'
import Icon from '../Icon/index'
import DatePicker from './index'


class Example extends Component {

  state = {
    value1: null,
    value2: {
      from: moment().toDate(),
      to: null,
    }
  }

  render() {
    const { value1, value2 } = this.state;
    return (
      <div>
        <Code code={`<Fieldset>
  <DatePicker.Range
    value={{
      from: new Date(),
      to: new Date()
    }}
    label={'Namn'}
    loading
    Icon={<Icon name={'mail'} />}
    onChange={({from, to}) => {}}
    onBlur={() => {}}
    onFocus={() => {}}
    options: {
      minDate: null,
      maxDate: null,
    },
  />
</Fieldset>`}
        />

        <div>
          <h4>Props</h4>
          <table width="100%" className="props-table">
            <tbody>


            </tbody>
          </table>
        </div>


        <div className="prev">
          <h3 className="label">DatePicker</h3>
          <Fieldset>
            <DatePicker
              value={value1}
              label={'Namn'}
              Icon={<Icon name={'calendar'} />}
              onChange={(value1) => {
                console.log({ value1 })
                this.setState({ value1 })
              }}
              onBlur={() => { }}
              onFocus={() => { }}
              options={{
                minDate: null,
                maxDate: null,
              }}
            />
          </Fieldset>
        </div>

        <div className="prev">
          <h3 className="label">DatePicker.Range</h3>
          <Fieldset>
            <DatePicker.Range
              value={value2}
              label={'Namn'}
              Icon={<Icon name={'calendar'} />}
              onChange={value2 => {
                console.log({ value2 })
                this.setState({ value2 })
              }}
              onBlur={() => { }}
              onFocus={() => { }}
              numberOfMonth={2}
            />
          </Fieldset>
        </div>



      </div>
    )
  }
}

export default Example;
