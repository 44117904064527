import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './fieldset.css';


const DefaultFieldset = ({className, style, children}) => {
  const cls = classNames(
    styles.fieldset,
    className
  );
  return (
    <fieldset style={style} className={cls}>
      { children }
    </fieldset>
  )
};

DefaultFieldset.defaultProps = {
  className: '',
  style: null,
};

DefaultFieldset.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};


const DefaultFieldsetRow = ({className, style, children}) => {
  const cls = classNames(
    styles.fieldRow,
    className
  );
  return (
     <div style={style} className={cls}>
      {children}
    </div>
  )
};

DefaultFieldsetRow.defaultProps = {
  className: '',
  style: null,
};

DefaultFieldsetRow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};


const DefaultFieldsetSpacer = ({className, style, height, children}) => {
  const cls = classNames(
    styles.fieldSpacer,
    className
  );
  return (
     <div style={{height, ...style}} className={cls}>
      {children}
    </div>
  )
};

DefaultFieldsetSpacer.defaultProps = {
  className: '',
  style: null,
  height: '25px'
};

DefaultFieldsetSpacer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  height: PropTypes.string,
};


class Fieldset extends Component {
  static Row = DefaultFieldsetRow;
  static Spacer = DefaultFieldsetSpacer;
  render() {
    return <DefaultFieldset {...this.props} />
  }
}


export default Fieldset;
