import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import '../config.css';
import styles from './list.css';

class List extends Component {
  render() {
    const { items, small, style, className } = this.props;

    const cls = classNames(
      className,
      styles.list,
      {
        [`${styles.small}`]: small,
      }
    );

    return (
      <ul className={cls} style={style}>
        {items.map((item, index) => (<li key={item.key || `list-${index}`}>
          <div className={styles.label}>{item.label}</div>
          { item.value && <div className={styles.value}>{item.value}</div>}
        </li>))
        }
      </ul>
    )
  }
}
List.defaultProps = {
  className: '',
  style: null,
  small: false,
  items: []
};

List.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    value: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    key: PropTypes.string.isRequired,
  })),
  small: PropTypes.bool
};

export default List;
