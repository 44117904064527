import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import './alert.css';

import styles from './alert.css'

const defaultProps = {
  className: '',
  style: null,
  Icon: null,
}
const propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.element,
}


const DefaultAlert = (props) => {
  const cls = classNames(
    styles.alert,
    {
      [`${styles.alignLeft}`]: props.alignLeft,
      [`${styles.hasIcon}`]: props.Icon
    },
    props.className
  );
  return (
    <div className={cls} style={props.style}>
      {props.Icon && (
        <div className={styles.icon}>
          { props.Icon }
        </div>
      )}
      {props.children}
    </div>
  )
}
DefaultAlert.propTypes = propTypes;
DefaultAlert.defaultProps = defaultProps;

const AlertWarning = (props) => (
  <DefaultAlert {...props} className={`${styles.warning} ${props.className}`} />
)
AlertWarning.propTypes = propTypes;
AlertWarning.defaultProps = defaultProps;

const AlertError = (props) => (
  <DefaultAlert {...props} className={`${styles.error} ${props.className}`} />
)
AlertError.propTypes = propTypes;
AlertError.defaultProps = defaultProps;

const AlertSuccess = (props) => (
  <DefaultAlert {...props} className={`${styles.success} ${props.className}`} />
)
AlertSuccess.propTypes = propTypes;
AlertSuccess.defaultProps = defaultProps;


class Alert extends Component {

  static Warning = AlertWarning;
  static Error = AlertError;
  static Success = AlertSuccess;

  render() {
    return <DefaultAlert {...this.props} />
  }
}



export default Alert;