import React from 'react';

import PropTypes from 'prop-types'

import css from './avatar.css';
import '../config.css'


const initals = fullName => {
  if (!fullName) return ':)';
  const list = fullName.split(' ');
  if (list.length === 0) return ':)';
  if (list.length === 1) return list[0].substr(0, 1).toUpperCase();

  const firstName = list.find(n => /^[a-zA-Z]/.test(n));
  const lastName = list.reverse().find(n => /^[a-zA-Z]/.test(n));
  if (list.length === 1) return list[0].substr(0, 1).toUpperCase();
  return `${firstName.substr(0, 1)}${lastName.substr(0, 1)}`.toUpperCase();
};


const randomColor = (name = '') => {
  if(!name) return '000';
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();
  return "00000".substring(0, 6 - c.length) + c;
};


const Avatar = ({ name = null, img = null }) => {
  if (img) {
    return (
      <div className={css.imgAvatar}>
        <img src={img} className={css.img} alt="avatar"/>
      </div>
    )
  }

  return (
    <div className={`${css.symbol}`} style={{ backgroundColor: `#${randomColor(name)}` }}>{initals(name)}</div>
  )
};


Avatar.defaultProps = {
  name: '',
  image: '',
};

Avatar.propTypes = {
  name: PropTypes.string,
  image: PropTypes.string,
};


export default Avatar;
