import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from '../Loader';
import Range from './Range';
import Picker from './Picker';
import styles from './datepicker.css';


class DefaultPicker extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      inFocus: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value && nextProps.type === 'Picker') {
      return {
        active: (nextProps.value !== '' || nextProps.value !== null)
      };
    }
    if (nextProps.value && nextProps.type === 'Range') {
      return {
        active: (nextProps.value.from !== null || nextProps.value.to !== null)
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.value !== prevProps.value) && !prevState.inFocus) {
      this.validate()
    }
  }

  validate = () => {
    const isValid = this.props.validate(this.props.value);
    this.setState({ isValid })
  };

  onFocus = () => {
    const { value, disabled, type } = this.props;
    if (disabled) {
      return
    }
    if (
      (type === 'Picker' && value !== null) ||
      (type === 'Range' && value && (value.from !== null || value.to !== null))
    ) {
      this.setState({ active: true, inFocus: true, })
    }
    this.props.onFocus();
  };

  onBlur = () => {
    this.validate();
    this.props.onBlur();
  };

  renderIcon() {
    const { Icon, loading } = this.props;
    if (loading) {
      return (
        <div className={styles.ficon}>
          <Loader small color={'gray'} />
        </div>
      )
    }
    if (Icon !== false && typeof Icon === 'string') {
      return (
        <div className={styles.ficon}>
          <i className={Icon}></i>
        </div>
      )
    } else if (Icon !== false) {
      return (
        <div className={styles.ficon}>
          {Icon}
        </div>
      )
    }
    return null;
  }

  renderInput() {
    const {
      label,
      value,
      Component,
      options,
      onChange,
      defaultDate,
      minDate,
      maxDate,
      numberOfMonth,
    } = this.props;
    return (
      <React.Fragment>
        <label className={styles.label}>{label}</label>
        <div className={styles.picker}>
          <Component
            onChange={(value) => {
              const { type } = this.props;
              if (
                (type === 'Picker' && value !== null) ||
                (type === 'Range' && value && (value.from !== null || value.to !== null))
              ) {
                this.setState({ active: true, inFocus: true, })
              }
              onChange(value);
            }}
            value={value}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            defaultDate={defaultDate}
            minDate={minDate}
            maxDate={maxDate}
            numberOfMonth={numberOfMonth}
          />
        </div>
      </React.Fragment>
    )
  }

  render() {
    const { active, inFocus, isValid } = this.state;
    const {
      Icon,
      className,
      error,
      disabled,
      children,
      loading,
    } = this.props;

    const cls = classNames(
      styles.field,
      className,
      {
        [`${styles.disabled}`]: disabled,
        [styles.hasIcon]: Icon || loading,
        [`${styles.active}`]: active || inFocus,
      }
    );

    return (
      <div className={cls}>
        {this.renderIcon()}
        {this.renderInput()}
        {children}
      </div>

    )
  }
}
const RangePicker = (props) =>
  <DefaultPicker {...props} type={'Range'} Component={Range} />


class DatePicker extends Component {
  static Range = RangePicker
  render() {
    return (
      <DefaultPicker {...this.props} type={'Picker'} Component={Picker} />
    )
  }
}

DefaultPicker.defaultProps = {
  Icon: false,
  className: '',
  label: '',
  loading: false,
  error: false,
  validate: (_) => true,
  tabIndex: 0,
  disabled: false,
  readOnly: false,
  children: null,
  options: {
    minDate: null,
    maxDate: null,
    defaultDate: new Date()
  },
  type: 'Picker',
  onChange: (_) => { },
  onBlur: () => { },
  onFocus: () => { }
}
DefaultPicker.propTypes = {
  Icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element
  ]),
  className: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  validate: PropTypes.func,
  tabIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.element,
  options: PropTypes.shape({
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    defaultDate: PropTypes.instanceOf(Date),
  }),
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}


export default DatePicker;
