import React, { Component } from 'react'
import Code from '../doc/Code/'
import Icon from '../Icon/';
import Paragraph from '../Paragraph/';
import BoxSelector from './index';


class Example extends Component {
  state = {
    value: 'simple',
  };

  render() {
    return (
      <div>
        <Code code={`<BoxSelector
  value='file'
  vertical
  large
  className={''}
  onChange={(key) => console.log('You selected', key)}
  options={[
    {
      Icon: <Icon name={'card'} />,
      label: 'Simple',
      value: 'simple',
      Component: <span>When you have a few salaries to pay out.</span>
    },
    {
      Icon: <Icon name={'create'} />,
      label: 'From file',
      value: 'file'
    }
  ]}
/>`}
        />

        <div>
          <h4>Props</h4>
          <table width="100%" className="props-table">
            <tbody>


            </tbody>
          </table>
        </div>


        <div className="prev">
          <h3 className="label">Box Selector</h3>
          <BoxSelector
            value={this.state.value}
            options={[
              {
                Icon: <Icon name={'card'} />,
                label: 'Simple',
                value: 'simple',
                title: 'Here is a tooltipWhen you ha',
                disabled: true,
                Component: <Paragraph small>When you have a few salaries to pay out.</Paragraph>
              },
              {
                Icon: <Icon name={'create'} />,
                label: 'From file',
                value: 'file',
                Component: <Paragraph small>When you have many salaries to pay out.</Paragraph>
              }
            ]}
            onChange={value => this.setState({ value })}
          />
        </div>

        <div className="prev">
          <h3 className="label">Box Selector large</h3>
          <BoxSelector
            large
            value={this.state.value}
            options={[
              {
                Icon: <Icon name={'card'} />,
                label: 'Simple',
                value: 'simple',
                title: 'Here is a tooltipWhen you ha',
                Component: <Paragraph small>When you have a few salaries to pay out.</Paragraph>
              },
              {
                Icon: <Icon name={'cloud-upload'} size={30} />,
                label: 'From file',
                value: 'clound',
                Component: <Paragraph small>When you have many salaries to pay out.</Paragraph>
              }
            ]}
            onChange={value => this.setState({ value })}
          />
        </div>

        <div className="prev">
          <h3 className="label">Box Selector vertical</h3>
          <BoxSelector
            vertical
            value={this.state.value}
            options={[
              {
                Icon: <Icon name={'card'} />,
                label: 'Option 1',
                value: 'opt1',
                Component: <Paragraph small>When you have a few salaries to pay out.</Paragraph>
              },
              {
                Icon: <Icon name={'cloud-upload'} size={30} />,
                label: 'Option 2',
                value: 'opt2',
                Component: <Paragraph small>When you have many salaries to pay out.</Paragraph>
              }
            ]}
            onChange={value => this.setState({ value })}
          />
        </div>

        <div className="prev">
          <h3 className="label">Box Selector disabled</h3>
          <BoxSelector
            value={this.state.value}
            options={[
              {
                disabled: true,
                Icon: <Icon name={'card'} />,
                label: 'Simple',
                value: 'simple',
                title: 'Du kan inte betala med kort!',
                Component: <Paragraph small>When you have a few salaries to pay out.</Paragraph>
              },
              {
                Icon: <Icon name={'create'} />,
                label: 'From file',
                value: 'file',
                Component: <Paragraph small>When you have many salaries to pay out.</Paragraph>
              }
            ]}
            onChange={value => this.setState({ value })}
          />
        </div>

      </div>
    )
  }
}
export default Example;