import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import '../config.css'
import styles from './strong.css'

export default class Strong extends Component {
  render() {
    const { children, className, small, large, inline, style } = this.props;
    const cls = classNames(
      styles.strong,
      className,
      {
        [`${styles.small}`]: small,
        [`${styles.large}`]: large,
        [`${styles.inline}`]: inline,
      }
    );
    return (
      <b style={style} className={cls}>
        { children }
      </b>
    )
  }
}

Strong.defaultProps = {
  className: '',
  style: null,
  medium: false,
  large: false,
  small: false,
  inline: true,
};
Strong.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  inline: PropTypes.bool,
};