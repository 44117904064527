import React from 'react'
import Code from '../doc/Code/'
import Fieldset from './index';


const example = () => (
  <div>
    <div>
      <Code code={`<Fieldset>
  <Input
    value={'Test}
  />
</Fieldset>`}
      />


      <Code code={`<Fieldset>
    <Fieldset.Row>
        <Input
          label={'First name}
        />
        <Input
          label={'Last name}
        />
    </Fieldset.Row>
</Fieldset>`}
      />
    </div>
    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>
          <tr>
            <td>className</td>
            <td>string</td>
          </tr>
          <tr>
            <td>style</td>
            <td>object</td>
          </tr>
          <tr>
            <td>small</td>
            <td>bool</td>
          </tr>
          <tr>
            <td>margin</td>
            <td>bool</td>
          </tr>
        </tbody>
      </table>
    </div>



  </div>
)
export default example;