import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../config.css'
import styles from './subtitle.css';

/*
 * THE DEFAULT BUTTON 
 */
const DefaultSubtitle = ({ small, style, margins, className, children, line }) => {

  const cls = classNames(
    styles.subtitle,
    {
      [`${styles.small}`]: small,
      'default-margins': margins
    },
    className
  );
  return (
    <h3 style={style} className={cls}>
      { children }
    </h3>
  )
}
DefaultSubtitle.defaultProps = {
  className: '',
  style: null,
  small: false,
  margins: true,
};
DefaultSubtitle.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  small: PropTypes.bool,
  margins: PropTypes.bool,
};

class Subtitle extends Component {
  render() {
    return <DefaultSubtitle {...this.props} />
  }
}
export default Subtitle;