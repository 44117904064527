import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../config.css'
import styles from './radio.css'

export default class Radio extends Component {

  state ={
    selectedIndex: 0,
    value: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      //Perform some operation here
      let index = -1;
      for (let i = 0; i < nextProps.options.length; i++) {
        if (nextProps.value === nextProps.options[i].value) {
          index = i;
          break;
        }
      }
      if (index < 0 && nextProps.options.length > 0) {
        return { value: nextProps.options[0].value, selectedIndex: 0 }
      }
      return { value: nextProps.value, selectedIndex: index }
    }
    return null
  }

  componentDidMount() {
    if (this.state.value !== '') {
      this.props.onChange(this.state.value)
    }
  }

  renderRadio(v) {
    return (
      <div className={`${styles.radioMark} ${ v ? styles.checked : '' }`}></div>
    )
  }

  render() {
    const { selectedIndex } = this.state;
    const { options } = this.props;
    return (
      <ul className={styles.radio}>
        {
          options.map((option, index) => (
            <li 
              onClick={() => {
                this.props.onChange(option.value)
              }} 
              key={`option-${option.value}`}
              className={(selectedIndex === index) ? styles.selected : ''}
            >
              <div className={styles.radiomarkContainer}>
                { this.renderRadio(selectedIndex === index) }
              </div>
              <div className={styles.radioContent}>
                <b className={styles.label}>{option.label}</b>
                <div className={styles.description}>{option.description}</div>
              </div>
            </li>))
        }
      </ul>
    )
  }
}
Radio.defaultProps = {
  className: '',
  options: [],
  onChange: () => {}
};
Radio.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  })),
  onChange: PropTypes.func
}