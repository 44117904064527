import React, { Component } from 'react'
import moment from 'moment-timezone';
import classNames from 'classnames';
import Icon from '../Icon/index'
import PropTypes from 'prop-types';
import styles from './calendar.css';

class Day extends Component {

  onPress = (date) => {
    const { thisDay, selectedDay, show } = this.props;
  }

  render() {
    const now = moment()
    const { thisDay, onClick } = this.props;

    if (!thisDay.isSame(this.props.date, 'month')) {
      return <li className={styles.calendarDay}></li>;
    }
    const cls = classNames(
      styles.calendarDay,
      {
        [`${styles.disabled}`]: this.props.minDate && thisDay.isBefore(moment(this.props.minDate)),
        [`${styles.hover}`]: this.props.from && this.props.hoverDate && thisDay.isBetween(this.props.from, this.props.hoverDate) && this.props.range,
        [`${styles.hoverEnd}`]: this.props.hoverDate && thisDay.isSame(this.props.hoverDate, 'day') && this.props.range,
        [`${styles.from}`]: this.props.from && thisDay.isSame(this.props.from, 'day') && this.props.range,
        [`${styles.selected}`]: this.props.from && thisDay.isSame(this.props.from, 'day') && !this.props.range,
        [`${styles.to}`]: this.props.to && thisDay.isSame(this.props.to, 'day'),
        [`${styles.active}`]: this.props.from && this.props.to && thisDay.isBetween(this.props.from, this.props.to),

        [`${styles.today}`]: thisDay.isSame(now, 'day')
      }
    );

    return (
      <li
        onClick={() => onClick(thisDay)}
        className={cls}
        onMouseEnter={() => this.props.onHover(thisDay)}
      >
        <span>{thisDay.date()}</span>
      </li>
    )
  }
}

export default class Calendar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      calendars: [],
      date: moment(),
      from: null,
      to: null,
      hoverDate: null
    }
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.from !== prevState.from || nextProps.to !== prevState.to) {
      return {
        from: (nextProps.from) ? moment(nextProps.from) : null,
        to: (nextProps.to) ? moment(nextProps.to) : null
      };
    }
    return null;
  }

  componentDidMount() {
    this.generateMonth(this.props.defaultDate)
  }

  generateMonth(date) {
    let calendars = []
    const { numberOfMonth } = this.props;

    const endMonth = moment(date).endOf('month').add(numberOfMonth - 1, 'month')
    for (let month = moment(date); month.isSameOrBefore(endMonth); month.add(1, 'month')) {
      let startWeek = moment(month).startOf('month')
      let endWeek = moment(month).endOf('month')

      let calendar = []
      for (let week = moment(startWeek); week.isSameOrBefore(endWeek); week.add(1, 'week')) {
        calendar.push({
          week: week,
          days: Array(7).fill(0).map((n, i) => moment(week).startOf('isoWeek').clone().add(n + i, 'day'))
        })
      }
      calendars.push({
        calendar,
        month: startWeek
      })
    }

    this.setState({ calendars })
  }

  /*
   * ACTIONS
  */
  nextMonth = () => {
    this.setState({
      date: moment(this.state.date).add(1, 'month')
    }, () => {
      this.generateMonth(this.state.date)
    })
  }
  prevMonth = () => {
    this.setState({
      date: moment(this.state.date).subtract(1, 'month')
    }, () => {
      this.generateMonth(this.state.date)
    })
  }

  onHover = day => {
    const { hoverDate, from, to } = this.state;
    if (from !== null && to === null && from.isBefore(day, 'day')) {
      this.setState({ hoverDate: day })
    } else if (hoverDate !== null) {
      this.setState({ hoverDate: null })
    }
  }

  onDayClick = day => {
    const {  minDate, maxDate, range } = this.props;
    const { from, to } = this.state;
    let newRange = { from, to }

    if (day.isBefore(moment(minDate), 'day')) {
      return
    }

    if (from === null) {
      newRange = { ...newRange, from: day };
    } else if (to === null && day.isSameOrAfter(from, 'day') && range) {
      newRange = { ...newRange, to: day };
    } else if (to === null && day.isBefore(from, 'day')) {
      newRange = { ...newRange, from: day, to: null }
    } else if (to !== null && from !== null) {
      newRange = { ...newRange, from: day, to: null }
    }

    this.setState(newRange, () => {
      this.props.onChange(newRange);
    })
  }

  renderHeader() {
    const { calendars } = this.state;
    return (
      <div className={styles.calendarHeader}>
        <span onClick={this.prevMonth} className={`${styles.arrows} ${styles.pointLeft}`}>
          <Icon name={'arrow-back'} />
        </span>
        {calendars.map(({ month }) => (
          <div className={styles.headerMonth} key={month}>
            <span>{month.format('MMM YY')}</span>
          </div>
        ))}
        <span onClick={this.nextMonth} className={`${styles.arrows} ${styles.pointRight}`}>
          <Icon name={'arrow-forward'} />
        </span>
      </div>
    )
  }

  renderCalendarList() {
    const { calendars, hoverDate, from, to } = this.state;
    return (
      <div className={styles.calendarList}>
        {calendars.map(({ calendar, month }) => (
          <div className={styles.calendar} key={month}>
            <ul className={styles.calendarMonth}>
              {calendar.map((week, index) => (
                <ul key={`week-${index}`} className={styles.calendarWeek}>
                  {week.days.map((day, dayIndx) => (
                    <Day
                      key={`d-${index}-${dayIndx}`}
                      thisDay={day}
                      date={month}
                      from={from}
                      to={to}
                      hoverDate={hoverDate}
                      onHover={this.onHover}
                      onClick={this.onDayClick}
                      {...this.props}
                    />
                  ))}
                </ul>
              ))}
            </ul>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {
      visible
    } = this.props;

    const cls = classNames(
      styles.dateValue,
      {
        [`${styles.visible}`]: visible,
      }
    );
    return (
      <div className={cls}>
        <div className={styles.calendarContainer}>
          {this.renderHeader()}
          {this.renderCalendarList()}
        </div>

      </div>
    )
  }
}

Calendar.defaultProps = {
  visible: false,
  minDate: null,
  maxDate: null,
  range: false,
  from: null,
  to: null,
  onChange: () => {},
  numberOfMonth: 2
}
Calendar.propTypes = {
  visible: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  from: PropTypes.instanceOf(moment),
  to: PropTypes.instanceOf(moment),
  range: PropTypes.bool,
  onChange: PropTypes.func,
  numberOfMonth: PropTypes.number
}
