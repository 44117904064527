import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './card.css'

const Card = ({ children, className, style }) => {

  const cls = classNames(
    styles.card,
    className
  );

  return (
    <div style={style} className={cls}>
      {children}
    </div>
  )
}

Card.defaultProps = {
  className: '',
  style: null,
  children: null
}
Card.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any
}

export default Card;