import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './input.css';
import Picker from './Picker';
import Loader from '../Loader';
import Icon from '../Icon';
import ToolTip from '../ToolTip';
import '../config.css'

class TextInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      inFocus: false,
      isValid: true,
      errorCache: {[this.props.value]: this.props.error},
      warningCache: {[this.props.value]: this.props.warning}
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return {
        active: (nextProps.value !== '')
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.value !== prevProps.value) && !prevState.inFocus) {
      this.validate()
    }

    // Since this is managed component and both value and error are passed as props we have to map which values
    // generates warnings and errors, since if a value and error is passed, and the user changes the value, the
    // error prop is generally not changed until the use submits the form again, but since the value has changed
    // the error is no longer applicable, even if it is still passed as a prop.
    if (this.props.warning && (this.props.warning !== prevProps.warning)) {
      this.setState({ warningCache: {...this.state.warningCache, [this.props.value]: this.props.warning} })
    }
    if (this.props.error && (this.props.error !== prevProps.error)) {
      this.setState({ errorCache: {...this.state.errorCache, [this.props.value]: this.props.error} })
    }
  }

  validate = () => {
    const isValid = this.props.validate(this.props.value);
    this.setState({ isValid })
  };

  onBlur = () => {
    const { value } = this.props;
    this.validate();
    if (value === '') {
      this.setState({ active: false, inFocus: false })
    }
    this.props.onBlur();
  };

  onFocus = () => {
    const { value, disabled } = this.props;
    if (disabled) return;

    if (value === '') {
      this.setState({ active: true, inFocus: true, })
    }
    this.props.onFocus();
  };

  onChange = (event) => {
    const { value } = event.target;
    this.setState({ isValid: true });
    this.props.onChange(value)
  };

  onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.props.onSubmit(event)
    }
    this.props.onKeyDown(event)
  };

  renderLeftIcon() {
    const { leftIcon, leftIconStyle } = this.props;
    if (leftIcon !== false && typeof leftIcon === 'string') {
      return (
        <div className={`${styles.ficon} ${styles.ficonLeft}`} style={leftIconStyle}>
          <i className={leftIcon}></i>
        </div>
      )
    } else if (leftIcon !== false) {
      return (
        <div className={`${styles.ficon} ${styles.ficonLeft}`} style={leftIconStyle}>
          {leftIcon}
        </div>
      )
    }
    return null;
  }

  renderRightIcon() {
    const { rightIcon, loading, rightIconStyle } = this.props;
    if (loading) {
      return (
        <div className={`${styles.ficon} ${styles.ficonRight}`} style={rightIconStyle}>
          <Loader small color={'gray'} />
        </div>
      )
    }
    if (rightIcon !== false && typeof rightIcon === 'string') {
      return (
        <div className={`${styles.ficon} ${styles.ficonRight}`} style={rightIconStyle}>
          <i className={rightIcon}></i>
        </div>
      )
    } else if (rightIcon !== false) {
      return (
        <div className={`${styles.ficon} ${styles.ficonRight}`} style={rightIconStyle}>
          {rightIcon}
        </div>
      )
    }
    return null;
  }

  renderErrorIcon(message) {
    /* We can't set the width of the tooltip to dynamically adjust after the width of the
    message through CSS only, so we approximate what the width will need to be. */
    const { active, inFocus } = this.state;
    let width = 7 * message.length;

    return (
      <ToolTip
        label={(
          <div className={styles.errorMessage}>
            <Icon name={'alert'} size={(active || inFocus) ? 11 : 13} color="#b50707"/>
          </div>
        )}
        width={`${width}px`}
        text={message}
        style={{position:"absolute", margin: "2px", bottom: ((active || inFocus) ? "0px" : "23px")}}
      />
    )
  }

  renderWarningIcon(message) {
    /* We can't set the width fo the tooltip to dynamically adjust after the width of the
    message through CSS only, so we approximate what the width will need to be. */
    const { active, inFocus } = this.state;
    let width = 7 * message.length;

    return (
      <ToolTip
        label={(
          <div className={styles.errorMessage}>
            <Icon name={'alert'} size={(active || inFocus) ? 11 : 13} color="#E36722"/>
          </div>
        )}
        width={`${width}px`}
        text={message}
        style={{position:"absolute", margin: "2px", bottom: ((active|| inFocus) ? "0px" : "23px")}}
      />
    )
  }

  renderComponent() {
    const { Component } = this.props;
    return (
      <div className={styles.componentWrapper}>
        {Component}
      </div>
    )
  }

  renderInput() {

    const {
      label,
      value,
      readOnly,
      onKeyUp,
      tabIndex,
      disabled,
      loading,
      type,
      inputMode,
      pattern,
      maxLength,
      onReady,
      name,
      autoComplete,
      innerRef,
      inputStyle,
      labelStyle,
    } = this.props;

    const error = this.state.errorCache[value];
    const warning = this.state.warningCache[value]

    return (

      <React.Fragment>
        <label className={styles.label} style={labelStyle}>
          {label}
          {error && this.renderErrorIcon(error)}
          {(warning && !(error)) && this.renderWarningIcon(warning)}
        </label>
        <input
          value={value}
          name={name}
          type={type}
          inputMode={inputMode}
          pattern={pattern}
          maxLength={maxLength}
          className={styles.input}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          ref={innerRef ? innerRef : (r) => onReady(r)}
          onKeyUp={onKeyUp}
          tabIndex={tabIndex}
          autoComplete={autoComplete}
          readOnly={readOnly || disabled }
          style={inputStyle}
        />
      </React.Fragment>
    )
  }

  render() {

    const {
      active,
      inFocus,
      isValid,
    } = this.state;

    const {
      leftIcon,
      rightIcon,
      className,
      disabled,
      children,
      loading,
      Component,
      style,
      value
    } = this.props;

    const error = this.state.errorCache[value];
    const warning = this.state.warningCache[value];

    const cls = classNames(
      styles.field,
      className,
      {
        [`${styles.disabled}`]: disabled,
        [styles.hasLeftIcon]: leftIcon || loading,
        [styles.hasRightIcon]: rightIcon || loading,
        [`${styles.active}`]: active || inFocus,
        [`${styles.error}`]: error || !isValid,
        [`${styles.warning}`]: warning && !(error || !isValid)   /* error overrides warning */
      }
    );

    return (
      <div className={cls} style={style}>
        {this.renderLeftIcon()}
        {!Component && this.renderInput()}
        {Component && this.renderComponent()}
        {children}
        {this.renderRightIcon()}
      </div>

    )
  }
}

TextInput.defaultProps = {
  leftIcon: false,
  rightIcon: false,
  className: '',
  value: '',
  placeholder: '',
  label: '',
  pattern: null,
  inputMode: 'default',
  type: 'text',
  loading: false,
  error: '',
  warning: '',
  validate: (_) => true,
  name: null,
  tabIndex: 0,
  disabled: false,
  readOnly: false,
  Component: null,
  maxLength: null,
  innerRef: null,
  autoComplete: 'on',

  style: null,
  inputStyle: null,
  labelStyle: null,
  leftIconStyle: null,
  rightIconStyle: null,

  onChange: (_) => { },
  onBlur: () => { },
  onFocus: () => { },
  onKeyUp: () => { },
  onKeyDown: () => { },
  onSubmit: () => { },
  onReady: () => { }
};

TextInput.propTypes = {
  leftIcon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element
  ]),
  rightIcon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.element
  ]),
  Component: PropTypes.element,
  name: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.oneOf(['text', 'email', 'tel', 'url']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  pattern: PropTypes.string,
  inputMode: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  warning: PropTypes.string,
  validate: PropTypes.func,
  tabIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,

  style: PropTypes.object,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  leftIconStyle: PropTypes.object,
  rightIconStyle: PropTypes.object,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func,
  onReady: PropTypes.func
};

const Input = React.forwardRef((props, ref) => (
  <TextInput innerRef={ref} {...props} />
));

Input.Picker = Picker;

export default Input
