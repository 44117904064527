import React from 'react'
import Code from '../doc/Code/'
import Link from './index'
import Paragraph from '../Paragraph/'

const example = () => (
  <div>
    <Code code={`<Link onClick={() => {}}>Read more</Link>`}
    />

    <div>
      <h4>Props</h4>
      <table width="100%" className="props-table">
        <tbody>


        </tbody>
      </table>
    </div>


    <div className="prev">
      <h3 className="label">Link</h3>
      <Link onClick={() => { }}>Read more</Link>
    </div>

    <div className="prev">
      <h3 className="label">Link in paragraph</h3>
      <Paragraph>
        Fill in the amount and description so that a <Link onClick={() => { }}>link</Link> is generated for your pay request
        
      </Paragraph>
    </div>

     <div className="prev">
      <h3 className="label">Link primary in paragraph</h3>
      <Paragraph>
        Fill in the amount and description so that a <Link primary onClick={() => { }}>link</Link> is generated for your pay request
        
      </Paragraph>
    </div>
    <div className="prev">
      <h3 className="label">Link primary in paragraph</h3>
      <Paragraph>
        Fill in the amount and description so that a <Link bold primary onClick={() => { }}>link</Link> is generated for your pay request
        
      </Paragraph>
    </div>


  </div>
)
export default example;