import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types'

import Avatar from "../Avatar";
import Icon from '../Icon/';
import Input from '../Input';
import Link from '../Link';

import css from './searchinput.css';
import '../config.css'


const SearchInput = ({ search, onSelect, value, description, ...props }) => {
  const [showResults, setShowResults] = useState(false);
  const { loading, results } = search(value);

  const renderRightIcon = () => {
    if (results.length === 0) {
      return null
    }
    if (showResults) {
      return (
        <Link onClick={() => setShowResults(false)}>
          <Icon name={'arrow-up'}/>
        </Link>
      )
    }
    if (!showResults) {
      return (
        <Link onClick={() => setShowResults(true)}>
          <Icon name={'arrow-down'}/>
        </Link>
      )
    }
  };

  return (
    <div className={css.searchComponent}>
      <div className={css.searchContainer}>
        <Input
          loading={loading}
          rightIcon={renderRightIcon()}
          value={value}
          onFocus={() => {setTimeout(() => {setShowResults(true)}, 200)}}  // timeout hack so it doesn't conflict with the setShowResults on the rightIcon
          onBlur={() => {setTimeout(() => {setShowResults(false)}, 200)}}  // timeout hack so it doesn't conflict with the setShowResults on the rightIcon
          {...props}
        />
      </div>

      <ul className={`${css.searchList} ${(showResults && results.length !== 0) ? '' : css.hidden}`}>
        <li className={css.resultDescription}>{description}</li>
        {results.map((result) => (
          <li className={css.result} onClick={() => onSelect(result)} key={result.label}>
            <div className={css.symbolContainer}>
              <Avatar name={result.description} />
            </div>
            <div>
              <span className={css.description}>{result.description}</span>
              <span className={css.information}>{result.information}</span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
};


SearchInput.defaultProps = {
  search: (_) => { },
  onSelect: (_) => { },
  description: '',
  value: '',
};

SearchInput.propTypes = {
  search: PropTypes.func,
  onSelect: PropTypes.func,
  description: PropTypes.string,
  value: PropTypes.string,
};


export default SearchInput;
