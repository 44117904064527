import React from 'react'
import PropTypes from 'prop-types'
import '../config.css'
import styles from './checkbox.css'

function Checkbox({style, id, label, description, value, onChange, descriptionStyle}) {
  return (
    <div className={styles.container}>
      <div className={styles.checkBoxContainer} style={style}>
        <input className={styles.input_checkBox}
               id={id}
               value={value}
               checked={value?'checked':''}
               type={'checkbox'} style={{display: 'none'}}
               onChange={() => {onChange(!value)}}/>
        <label className={styles.checkBox} htmlFor={id}>
                <span>
                    <svg width="12px" height="9px" viewBox="0 0 12 9">
                        <polyline points="1 5 4 8 11 1"/>
                    </svg>
                </span>
          {
            label &&
            <span className={styles.label}>{label}</span>
          }
        </label>
      </div>
      <p style={descriptionStyle}>{description}</p>
    </div>
);
}

export default Checkbox;

Checkbox.defaultProps = {
id: '',
label: '',
description: '',
value: false,
onChange: () => {},
style: null,
descriptionStyle:null
};

Checkbox.propTypes = {
id: PropTypes.string,
label: PropTypes.string,
description: PropTypes.string,
value: PropTypes.bool,
onChange: PropTypes.func,
style: PropTypes.object,
descriptionStyle: PropTypes.object
};
