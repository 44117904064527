import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './loader.css'

const DefaultLoader = ({ large, medium, small, inline, className, color }) => {
  const cls = classNames(
    styles.loader,
    {
      [`${styles.small}`]: small,
      [`${styles.medium}`]: medium,
      [`${styles.large}`]: large,
      [`${styles.inline}`]: inline
    },
    className
  )
  let size = 23
  let width = 2
  if (small) {
    size = 15
    width = 2
  } else if (large) {
    size = 40
  }
  const colorString = color.replace('#', '')
  return (
    <div className={cls}>
      <div className={styles.spinner}>
        <svg focusable={false} width={size} height={size} viewBox={'-1 -1 22 22'}>
          <defs>
            <linearGradient id={`gradient-${colorString}-0`} gradientUnits={'objectBoundingBox'} x1={0} y1={0} x2={0} y2={1}>
              <stop offset={'0%'} stopColor={color} stopOpacity={0} />
              <stop offset={'100%'} stopColor={color} stopOpacity={0.2} />
            </linearGradient>
            <linearGradient id={`gradient-${colorString}-1`} gradientUnits={'objectBoundingBox'} x1={1} y1={0} x2={0} y2={1}>
              <stop offset={'0%'} stopColor={color} stopOpacity={0.2} />
              <stop offset={'100%'} stopColor={color} stopOpacity={0.4} />
            </linearGradient>
            <linearGradient id={`gradient-${colorString}-2`} gradientUnits={'objectBoundingBox'} x1={1} y1={1} x2={0} y2={0}>
              <stop offset={'0%'} stopColor={color} stopOpacity={0.4} />
              <stop offset={'100%'} stopColor={color} stopOpacity={0.6000000000000001} />
            </linearGradient>
            <linearGradient id={`gradient-${colorString}-3`} gradientUnits={'objectBoundingBox'} x1={0} y1={1} x2={0} y2={0}>
              <stop offset={'0%'} stopColor={color} stopOpacity={0.6000000000000001} />
              <stop offset={'100%'} stopColor={color} stopOpacity={0.8} />
            </linearGradient>
            <linearGradient id={`gradient-${colorString}-4`} gradientUnits={'objectBoundingBox'} x1={0} y1={1} x2={1} y2={0}>
              <stop offset={'0%'} stopColor={color} stopOpacity={0.8} />
              <stop offset={'100%'} stopColor={color} stopOpacity={1} />
            </linearGradient>
            <linearGradient id={`gradient-${colorString}-5`} gradientUnits={'objectBoundingBox'} x1={0} y1={0} x2={1} y2={1}>
              <stop offset={'0%'} stopColor={color} stopOpacity={1} />
              <stop offset={'100%'} stopColor={color} stopOpacity={1.2000000000000002} />
            </linearGradient>
          </defs>
          <g fill={'none'} strokeWidth={width} transform={'translate(10,10)'}>
            <path d={'M 8.66,-5 A 10,10 0 0,1 8.66,5'} stroke={`url(#gradient-${colorString}-0)`} />
            <path d={'M 8.66,5 A 10,10 0 0,1 0,10'} stroke={`url(#gradient-${colorString}-1)`} />
            <path d={'M 0,10 A 10,10 0 0,1 -8.66,5'} stroke={`url(#gradient-${colorString}-2)`} />
            <path d={'M -8.66,5 A 10,10 0 0,1 -8.66,-5'} stroke={`url(#gradient-${colorString}-3)`} />
            <path d={'M -8.66,-5 A 10,10 0 0,1 0,-10'} stroke={`url(#gradient-${colorString}-4)`} />
            <path d={'M 0,-10 A 10,10 0 0,1 8.66,-5'} stroke={`url(#gradient-${colorString}-5)`} strokeLinecap={'round'} />
          </g>
        </svg>
      </div>
    </div>
  )
}
DefaultLoader.defaultProps = {
  className: '',
  style: null,
  small: false,
  medium: false,
  large: false,
  color: '#000',
}
DefaultLoader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  color: PropTypes.string,
}

const FillLoader = (props) => {
  return (
    <div className={styles.fillContainer}>
      <DefaultLoader
        {...props}
      />
    </div>
  )
}

export default class Loader extends Component {
  static Fill = FillLoader;
  render() {
    return <DefaultLoader {...this.props} />
  }
}
Loader.defaultProps = {
  className: '',
  style: null,
  small: false,
  medium: false,
  large: false,
  color: '#000'
}
Loader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  color: PropTypes.string
}