import React, {useState} from 'react';
import styles from './messageBubble.css'
import Icon from '../Icon';

const MessageBubble = ({title, text, onSkip, onClose, skipText, top, left, width, position, color}) => {
  const [display, setDisplay] = useState('showBubble');

  const hideBubble = () => {
    setDisplay('hideBubble');
  };

  return (
    <div className={styles[display]}
         style={{top: top, left: left, width: width, position:position}}>
      <div className={styles.bubble}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>{title}</p>
          <button className={styles.close}
                  onClick={() => {
                    hideBubble()
                    onClose()
                  }}
          >
            <Icon name={'close-circle'} color={color} size={20}/>
          </button>
        </div>
        <p className={styles.text}>{text}</p>
        <button className={styles.skip}
                onClick={() => onSkip()}>
          {skipText}
        </button>
      </div>
    </div>
  );
};

export default MessageBubble;
